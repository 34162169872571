import { Category, CategoryKey, CategoryOption, EmbeddedReportResponse, ReportMetadata } from './ReportModels';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { UserProfileContext, isOrgAdminOrHigher, isSourceSystemAdminOrHigher } from '../Shared/contexts/UserProfile';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ApiHelper from '../Shared/ApiHelper';
import { Breadcrumb } from 'react-bootstrap';
import { ChevronRight } from '@carbon/icons-react';
import { Embed } from 'powerbi-client';
import EmbeddedReport from './EmbeddedReport';
import { IdTokenClaims } from '../Shared/SharedModels';
import NavigationWarningModal from '../Shared/components/NavigationWarningModal';
import ReportActions from './ReportActions';
import SaveReportModal from './SaveReportModal';
import { useMsal } from '@azure/msal-react';
import { useNavigationPrompt } from '../Shared/hooks/useNavigationPrompt';

const ViewReport: FunctionComponent = () => {
    const { reportId, workspaceId, datasetId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const userProfile = useContext(UserProfileContext);

    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [embedUrl, setEmbedUrl] = useState<string | undefined>(undefined);
    const [reportName, setReportName] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [reportMetadata, setReportMetadata] = useState<ReportMetadata | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedCategoryOptionKeys, setSelectedCategoryOptionKeys] = useState<CategoryKey[]>([]);
    const [displaySaveReportModal, setDisplaySaveReportModal] = useState<boolean>(false); // Changed wording from show/setShow to display/setDisplay to attempt to reduce confusion.
    const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
    const [description, setDescription] = useState<string>('');
    const [report, setReport] = useState<Embed>();
    const [isSaveAs, setIsSaveAs] = useState<boolean>(false); // Perform different logic in SaveReportModal depending on this state
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useNavigationPrompt(showDialog);

    useEffect(() => {
        let newEditModeValue = false;
        if (reportMetadata?.type === 'Public') {
            newEditModeValue =
                searchParams.get('edit') === 'true' && !!reportMetadata && isSourceSystemAdminOrHigher(userProfile);
        } else {
            newEditModeValue =
                searchParams.get('edit') === 'true' && !!reportMetadata && isOrgAdminOrHigher(userProfile);
        }
        setEditMode(newEditModeValue);
        //we don't want to prevent navigation if the user has clicked save as
        setShowDialog(newEditModeValue && !isSaveAs);
    }, [searchParams, reportMetadata, userProfile.user?.roleKey, userProfile, showDialog, isSaveAs]);

    useEffect(() => {
        setIsLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/PbiReport/${workspaceId}/${reportId}`,
            'GET'
        ).then(async (result: Response) => {
            const reportMetadata: ReportMetadata = await result.json();
            const isPublic = reportMetadata.type === 'Public';
            setReportMetadata(reportMetadata);
            setSelectedCategoryOptionKeys(
                reportMetadata?.categories?.map((rpt) => {
                    return { CategoryKey: rpt.categoryKey };
                }) ?? []
            );
            setDescription(reportMetadata?.description ?? '');

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL +
                    `/PbiEmbed/view${isPublic ? '-public' : ''}/${workspaceId}/${reportId}/${datasetId}`,
                'GET'
            ).then(async (result: Response) => {
                const embedReportResponse: EmbeddedReportResponse = await result.json();

                setAccessToken(embedReportResponse.embedToken.token);
                setEmbedUrl(embedReportResponse.embedReport[0].embedUrl);
                setReportName(embedReportResponse.embedReport[0].reportName);
                setIsLoading(false);
            });
        });

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/PbiReport/categories'
        ).then(async (result: Response) => {
            const cats: Category[] = await result.json();
            const mappedCats = cats.map((cats: Category) => {
                return {
                    label: cats.categoryName,
                    value: cats.categoryKey,
                };
            });
            setCategoryOptions(mappedCats);
        });
    }, [api, instance, reportId, workspaceId, datasetId]);

    const idTokenClaims = instance.getAllAccounts()[0]?.idTokenClaims as unknown as IdTokenClaims;

    return (
        <div className="content-column-container">
            <div className="content-header flex-wrap">
                <Breadcrumb>
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/reports');
                        }}
                    >
                        Reports
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>View Report</Breadcrumb.Item>
                </Breadcrumb>
                <ReportActions
                    editMode={editMode}
                    createdByUser={!!idTokenClaims.email && idTokenClaims.email === reportMetadata?.createdBy}
                    reportMetadata={reportMetadata}
                    setReportMetadata={setReportMetadata}
                />
            </div>
            {!isLoading && (
                <>
                    <h4>{reportName}</h4>
                    <EmbeddedReport
                        accessToken={accessToken}
                        embedUrl={embedUrl}
                        workspaceId={workspaceId}
                        reportId={reportId}
                        editMode={editMode}
                        createdByUser={!!idTokenClaims.email && idTokenClaims.email === reportMetadata?.createdBy}
                        type="report"
                        description={description}
                        selectedCategoryOptionKeys={selectedCategoryOptionKeys}
                        setDisplaySaveReportModal={setDisplaySaveReportModal}
                        report={report}
                        setReport={setReport}
                        setIsSaveAs={setIsSaveAs}
                        isPublic={reportMetadata?.type === 'Public'}
                        datasetId={datasetId}
                        reportName={reportName}
                    />
                </>
            )}

            <SaveReportModal
                showModal={displaySaveReportModal}
                setShowModal={setDisplaySaveReportModal}
                categoryOptions={categoryOptions}
                selectedCategoryOptionKeys={selectedCategoryOptionKeys}
                setSelectedCategoryOptionKeys={setSelectedCategoryOptionKeys}
                setDescription={setDescription}
                description={description}
                report={report}
                isSaveAs={isSaveAs}
                reportName={reportName}
                reportMetadata={reportMetadata}
                setReportMetadata={setReportMetadata}
                isPublic={reportMetadata?.type === 'Public'}
            />

            <NavigationWarningModal
                showDialog={showPrompt as boolean}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
        </div>
    );
};

export default ViewReport;
