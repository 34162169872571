import {
    Checkbox,
    CheckboxCheckedFilled,
    ChevronDown,
    ChevronUp,
    Draggable as DraggableIcon,
} from '@carbon/icons-react';
import { DashboardLayout, DashboardVisual } from './DashboardModels';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import React, { FunctionComponent } from 'react';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './VisualsDropdown.module.css';
import useMenu from '../Shared/hooks/useMenu';

interface VisualsDropdownProps {
    visuals: DashboardVisual[];
    setVisuals: React.Dispatch<React.SetStateAction<DashboardVisual[]>>;
    savePreferences: (layout?: DashboardLayout, selectedVisuals?: DashboardVisual[]) => void;
}

const VisualsDropdown: FunctionComponent<VisualsDropdownProps> = ({
    visuals,
    setVisuals,
    savePreferences,
}: VisualsDropdownProps) => {
    const { isVisible, menuRef, toggleMenu } = useMenu();

    const moveVisual = (fromIndex: number, toIndex: number): void => {
        const newVisuals = [...visuals];

        const [visual] = newVisuals.splice(fromIndex, 1);
        newVisuals.splice(toIndex, 0, visual);

        setVisuals(newVisuals);
        savePreferences(undefined, newVisuals);
    };

    const handleDropEnd = (result: DropResult): void => {
        const { source, destination } = result;
        
        if (!destination) return;

        if (source.index === destination.index) return;

        moveVisual(source.index, destination.index);
    };

    const toggleVisual = (visual: DashboardVisual): void =>
        setVisuals((prevVisuals: DashboardVisual[]) => {
            const newVisuals = prevVisuals.map((prevVisual) => {
                if (prevVisual.name === visual.name) {
                    return { ...prevVisual, checked: !prevVisual.checked };
                }
                return prevVisual;
            });
            savePreferences(undefined, newVisuals);
            return newVisuals;
        });

    return (
        <div className={styles.container} ref={menuRef}>
            <Button
                data-testid="selectVisualsDropDown"
                id="visuals-dropdown-basic-button"
                variant="outline-primary"
                onClick={toggleMenu}
                title="Select Visuals"
            >
                <span className="mr-2">Select Visuals</span>
                <ChevronDown size={20} />
            </Button>

            {isVisible && (
                <div className={styles.menu}>
                    {!visuals.length ? (
                        <div className={styles.item}>
                            <span className={styles.emptyMessage}>No Visuals Found</span>
                        </div>
                    ) : (
                        <DragDropContext onDragEnd={handleDropEnd}>
                            <Droppable droppableId="visuals">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {visuals.map((visual, index) => (
                                            <Draggable
                                                key={index}
                                                index={index}
                                                draggableId={`visual-${index}`}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className={classNames(styles.item, {
                                                            [styles.draggingItem]: snapshot.isDragging,
                                                        })}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <button
                                                            type="button"
                                                            className={styles.button}
                                                            onClick={() => toggleVisual(visual)}
                                                        >
                                                            {visual.checked ? (
                                                                <CheckboxCheckedFilled
                                                                    size={20}
                                                                    className="select-visuals-checkbox"
                                                                    id={`checked-${index}`}
                                                                />
                                                            ) : (
                                                                <Checkbox
                                                                    size={20}
                                                                    className="select-visuals-checkbox"
                                                                    id={`unChecked-${index}`}
                                                                />
                                                            )}
                                                        </button>

                                                        <span className="mr-4">{visual.title}</span>

                                                        <div className={styles.moveContainer}>
                                                            <button
                                                                type="button"
                                                                className={styles.buttonIcon}
                                                                disabled={index === 0}
                                                                onClick={() => moveVisual(index, index - 1)}
                                                            >
                                                                <ChevronUp size={24} />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={styles.buttonIcon}
                                                                disabled={index === visuals.length - 1}
                                                                onClick={() => moveVisual(index, index + 1)}
                                                            >
                                                                <ChevronDown size={24} />
                                                            </button>
                                                        </div>

                                                        <div className={styles.draggable}>
                                                            <button
                                                                type="button"
                                                                className={styles.buttonIcon}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <DraggableIcon size={24} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </div>
            )}
        </div>
    );
};

export default VisualsDropdown;
