import './TopNav.css';

import { Nav, Navbar } from 'react-bootstrap';
import React, { Dispatch, FunctionComponent, SetStateAction, memo, useCallback, useContext } from 'react';

import AlertsDropdown from './AlertsDropdown';
import EnvironmentDropdown from './EnvironmentDropdown';
import FavoritesDropdown from './FavoritesDropdown';
import HelpDropdown from './HelpDropdown';
import UserDropdown from './UserDropdown';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';

export interface TopNavProps {
    setShowLeftNav: Dispatch<SetStateAction<boolean>>;
}

const TopNav: FunctionComponent<TopNavProps> = ({ setShowLeftNav }) => {
    const { isEccoviaUser } = useContext(UserProfileContext);

    const handleToggle = useCallback(() => {
        setShowLeftNav((prev) => !prev);
    }, [setShowLeftNav]);

    return (
        <Navbar
            className="navbar navbar-light bg-white topbar static-top shadow"
        >
            <button
                id="sidebarToggleTop"
                title="sidebarToggleTop"
                className="btn btn-link d-md-none rounded-circle mr-3"
                onClick={handleToggle}
            >
                <i className="fa fa-bars"></i>
            </button>
            <Nav className="navbar-nav ml-auto">
                    <FavoritesDropdown />
                    <HelpDropdown />
                    <AlertsDropdown />
                    {isEccoviaUser && <EnvironmentDropdown />}
                    <UserDropdown />
                </Nav>
        </Navbar>
    );
};

export default memo(TopNav);