import { Button, Spinner } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';

import { Save } from '@carbon/icons-react';

interface SaveButtonProps {
    onClick: () => void;
    isSaving?: boolean;
    isDisabled?: boolean;
}

const SaveButton: FunctionComponent<SaveButtonProps> = (props: SaveButtonProps) => {
    const { onClick, isSaving, isDisabled } = props;

    return (
        <Button disabled={isSaving || isDisabled} variant="primary" className="save-button" onClick={onClick}>
            {isSaving ? (
                <>
                    <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" /> Saving...
                </>
            ) : (
                <>
                    <Save size={24} className="button-icon-padding" />
                    Save
                </>
            )}
        </Button>
    );
};

export default SaveButton;
