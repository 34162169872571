import { COLUMNS, DashboardLayout, DashboardVisual, SPAN_TYPE } from './DashboardModels';
import React, { FunctionComponent } from 'react';

import { ChevronDown } from '@carbon/icons-react';
import { Dropdown } from 'react-bootstrap';

interface LayoutDropdownProps {
    layout: DashboardLayout;
    setLayout: React.Dispatch<React.SetStateAction<DashboardLayout>>;
    savePreferences: (layout?: DashboardLayout, selectedVisuals?: DashboardVisual[]) => void;
}

const LayoutDropdown: FunctionComponent<LayoutDropdownProps> = (props: LayoutDropdownProps) => {
    const { layout, setLayout, savePreferences } = props;

    return (
        <Dropdown className="mx-2">
            <Dropdown.Toggle
                id="layout-dropdown-basic-button"
                variant="outline-primary"
                data-testid="dropDownSelector">
                <img
                    className="button-icon-padding"
                    src={layout.icon}
                    alt="Layout"
                    height="24"
                />
                <ChevronDown size={20} />
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="layout-dropdown-menu"
                style={{ maxHeight: 'none', overflowY: 'hidden' }}
            >
                {[
                    {
                        columns: COLUMNS.ONE,
                        span: SPAN_TYPE.NONE,
                        icon: 'DashboardLayouts/column1.svg',
                        alt: 'Column 1',
                    },
                    {
                        columns: COLUMNS.TWO,
                        span: SPAN_TYPE.COLSPAN,
                        icon: 'DashboardLayouts/colspan.svg',
                        alt: 'Colspan',
                    },
                    {
                        columns: COLUMNS.TWO,
                        span: SPAN_TYPE.NONE,
                        icon: 'DashboardLayouts/column2.svg',
                        alt: 'Column 2',
                    },
                    {
                        columns: COLUMNS.THREE,
                        span: SPAN_TYPE.NONE,
                        icon: 'DashboardLayouts/column3.svg',
                        alt: 'Column 3',
                    },
                    {
                        columns: COLUMNS.TWO,
                        span: SPAN_TYPE.ROWSPAN,
                        icon: 'DashboardLayouts/rowspan.svg',
                        alt: 'Rowspan',
                    },
                ].map((layoutOption, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => {
                            const newLayout: DashboardLayout = {
                                columns: layoutOption.columns,
                                span: layoutOption.span,
                                icon: layoutOption.icon,
                            };

                            setLayout(newLayout);
                            savePreferences(newLayout, undefined);
                        }}
                    >
                        <img
                            src={layoutOption.icon}
                            alt={layoutOption.alt}
                            height="24"
                        />
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LayoutDropdown;
