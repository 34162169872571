import './DataTools.css';

import { AddFilled, ArrowRight, Download } from '@carbon/icons-react';
import { Button, Card, Container, Row, Spinner } from 'react-bootstrap';
import { DedupeProject, ProjectPhase } from './DataToolsModels';
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../Shared/contexts/UserProfile';

import AbandonProjectModal from './AbandonProjectModal';
import ApiHelper from '../Shared/ApiHelper';
import ProjectCard from '../Shared/components/ProjectCard';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const DataTools: FunctionComponent = () => {
    const [projects, setProjects] = useState<DedupeProject[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState<number | undefined>(undefined);
    const [showAbandonProjectModal, setShowAbandonProjectModal] = useState(false);

    const navigate = useNavigate();
    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);
    const userProfile = useContext(UserProfileContext);

    const userHasPhaseOneProjectInProgress = useCallback<() => boolean>(() => {
        return !!projects.find((project) => {
            const phaseOne = project.project_phase?.find((phase: ProjectPhase) => {
                return phase.project_phase === 1;
            });

            return !phaseOne?.end_time && project.exists_in_cache && project.created_by === userProfile.user?.upn;
        });
    }, [projects, userProfile.user?.upn]);

    const shouldHideResumeButton = useCallback<(project: DedupeProject) => boolean>(
        (project: DedupeProject) => {
            const phaseTwo = project.project_phase?.find((phase: ProjectPhase) => {
                return phase.project_phase === 2;
            });

            if (!phaseTwo?.start_time && (project.created_by !== userProfile.user?.upn || !project.exists_in_cache)) {
                return true;
            }

            return false;
        },
        [userProfile.user?.upn]
    );

    useEffect(() => {
        setIsLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_PYTHON_API_URL + `/projects`
        ).then(async (projectResponse) => {
            const projects: DedupeProject[] = await projectResponse.json();

            setProjects(projects);
            setIsLoading(false);
        });
    }, [api, instance]);

    return (
        <div className="content-column-container">
            <div className="content-header">
                <h1>Data Tools</h1>
                {isGlobalAdmin(userProfile) && (
                    <Button
                        className="file-download-button"
                        variant="outline-primary"
                        href={process.env.REACT_APP_PYTHON_API_URL + `/uci/download`}
                    >
                        <Download size={24} className="button-icon-padding" />
                        Download UCI
                    </Button>
                )}
            </div>
            <p className="data-tools-description">Use these tools to help ClientInsight improve your client data</p>
            <Button
                className="project-history-link"
                variant="link"
                onClick={() => {
                    navigate('history');
                }}
                data-testid="ProjectHistory"
            >
                Show Projects History
                <ArrowRight size={24} className="ml-2" />
            </Button>
            <Container fluid className="mt-4">
                <Row>
                    <ProjectCard
                        title="Upload Files for Processing"
                        body="Upload CSV files for processing into ClientInsight"
                        projectType="Upload"
                        onClick={() => {
                            navigate('upload');
                        }}

                    >
                        <AddFilled size={24} className="button-icon-padding" data-testid="UploadFiles"/>
                        Upload Files
                    </ProjectCard>
                    {!isLoading && !userHasPhaseOneProjectInProgress() && (
                        <ProjectCard
                            title="De-Duplicate Records"
                            body="Identify duplicate client records within a data source"
                            projectType="Dedupe"
                            onClick={() => {
                                navigate('dedupe?newProject=true');
                            }}
                        >
                            <AddFilled size={24} className="button-icon-padding" />
                            New Project
                        </ProjectCard>
                    )}
                    {!isLoading ? (
                        projects.map((project: DedupeProject) => {
                            return (
                                <ProjectCard
                                    key={'col-' + project.project_key}
                                    title={project.name}
                                    body={project.description}
                                    projectType={'Dedupe'}
                                    closable={!shouldHideResumeButton(project) || isGlobalAdmin(userProfile)}
                                    hideButton={shouldHideResumeButton(project)}
                                    onClose={() => {
                                        setShowAbandonProjectModal(true);
                                        setProjectToDelete(project.project_key);
                                    }}
                                    onClick={() => {
                                        navigate(`dedupe?projectName=${project.name}&projectId=${project.project_key}`);
                                    }}
                                >
                                    Resume Project
                                </ProjectCard>
                            );
                        })
                    ) : (
                        <Card className="text-center mx-3 my-1">
                            <Spinner className="centered-spinner mt-5" variant="primary" size="sm" animation="border" />
                            <Card.Body>
                                <Card.Title>Loading Projects</Card.Title>
                                <Card.Text>Please wait while we load your data projects</Card.Text>
                            </Card.Body>
                        </Card>
                    )}
                </Row>

                {!!projectToDelete && (
                    <AbandonProjectModal
                        projectId={projectToDelete}
                        setProjectId={setProjectToDelete}
                        show={showAbandonProjectModal}
                        setShow={setShowAbandonProjectModal}
                        setProjects={setProjects}
                    />
                )}
            </Container>
        </div>
    );
};

export default DataTools;
