import { CoC, HmisProject, HmisProjectType, Organization, SelectOption, SourceSystem } from '../Shared/SharedModels';
import { DashboardFilterPreferences, DashboardLayout, DashboardVisual } from './DashboardModels';
import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import ApiHelper from '../Shared/ApiHelper';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { UserProfileContext } from '../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';

interface DashboardFiltersProps {
    setFilterQuery: Dispatch<SetStateAction<string>>;
    selectedFilters?: DashboardFilterPreferences;
    setSelectedFilters: Dispatch<SetStateAction<DashboardFilterPreferences | undefined>>;
    saveDashboardPreferences: (
        preferredLayout?: DashboardLayout,
        selectedVisuals?: DashboardVisual[],
        filters?: DashboardFilterPreferences
    ) => void;
}

const DashboardFilters: FunctionComponent<DashboardFiltersProps> = (props: DashboardFiltersProps) => {
    const { setFilterQuery, selectedFilters, setSelectedFilters, saveDashboardPreferences } = props;

    const userProfile = useContext(UserProfileContext);
    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();
    const [filterDefaults] = useState(selectedFilters);

    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<SourceSystem>[]>([]);
    const [organizationOptions, setOrganizationOptions] = useState<SelectOption<Organization>[]>([]);
    const [coCOptions, setCoCOptions] = useState<SelectOption<CoC>[]>([]);
    const [projectTypeOptions, setProjectTypeOptions] = useState<SelectOption<HmisProjectType>[]>([]);
    const [areProjectTypesLoading, setAreProjectTypesLoading] = useState<boolean>(true);
    const [projectOptions, setProjectOptions] = useState<SelectOption<HmisProject>[]>([]);

    const populateOrganizations = useCallback(
        (sourceSystemKey: number) => {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/User/sourceSystems/' + sourceSystemKey + '/organizations'
            ).then(async (result: Response) => {
                const organizations: Organization[] = await result.json();
                setOrganizationOptions(
                    organizations.map((org) => ({
                        label: org.organizationName,
                        value: org,
                    }))
                );
            })
            .catch((error) => {
                    console.error('Error fetching organizations:', error);
            });
        },
        [api, instance]
    );
    const populateCocs = useCallback(
        (sourceSystemKey: number) => {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/User/cocs/' + sourceSystemKey
            ).then(async (result: Response) => {
                const cocs: CoC[] = await result.json();
                setCoCOptions(
                    cocs.map((coc) => {
                        return { label: coc.coCName, value: coc };
                    })
                );
            });
        },
        [api, instance]
    );
    const populateProjects = useCallback(
        (organizationKey: number, projectType: string) => {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/User/projects/' + organizationKey + '/' + projectType
            ).then(async (result: Response) => {
                const projects: HmisProject[] = await result.json();
                setProjectOptions(
                    projects.map((project) => {
                        return { label: project.projectName, value: project };
                    })
                );
            });
        },
        [api, instance]
    );
    const saveFilterPreferences = useCallback(
        (inputFilters: DashboardFilterPreferences, filterKeys: (keyof DashboardFilterPreferences)[]) => {
            const filters = {
                sourceSystem: selectedFilters?.sourceSystem,
                organization: selectedFilters?.organization,
                coc: selectedFilters?.coc,
                hmisProjectType: selectedFilters?.hmisProjectType,
                hmisProject: selectedFilters?.hmisProject,
            };

            filterKeys.forEach((filterKey: keyof DashboardFilterPreferences) => {
                filters[filterKey] = inputFilters[filterKey] as never;
            });

            setSelectedFilters(filters);
            saveDashboardPreferences(undefined, undefined, filters);
        },
        [
            saveDashboardPreferences,
            selectedFilters?.coc,
            selectedFilters?.sourceSystem,
            selectedFilters?.organization,
            selectedFilters?.hmisProject,
            selectedFilters?.hmisProjectType,
            setSelectedFilters,
        ]
    );

    //Initialization of non-dependent filters
    useEffect(() => {
        setSourceSystemOptions(
            userProfile.sourceSystems.map((sourceSystem) => ({
                label: sourceSystem.sourceSystemName,
                value: sourceSystem,
            }))
        );

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/projecttypes'
        ).then(async (result: Response) => {
            const projectTypes: HmisProjectType[] = await result.json();
            setProjectTypeOptions(
                projectTypes.map((projectType) => {
                    return { label: projectType.text, value: projectType };
                })
            );
            setAreProjectTypesLoading(false);
        });
    }, [api, instance, userProfile.sourceSystems]);

    //Initialization of filters that are dependent on other filters
    useEffect(() => {
        if (filterDefaults?.sourceSystem) {
            populateCocs(filterDefaults.sourceSystem.value.sourceSystemKey);
            populateOrganizations(filterDefaults.sourceSystem.value.sourceSystemKey);
        }

        if (filterDefaults?.organization && filterDefaults.hmisProjectType) {
            populateProjects(
                filterDefaults.organization.value.organizationKey,
                filterDefaults.hmisProjectType.value.value
            );
        }
    }, [
        filterDefaults?.hmisProjectType,
        filterDefaults?.organization,
        filterDefaults?.sourceSystem,
        populateCocs,
        populateOrganizations,
        populateProjects,
    ]);

    //Builds filter query string based on selected filters
    useEffect(() => {
        if (selectedFilters?.sourceSystem || selectedFilters?.hmisProjectType) {
            let newFilterQuery = '&filter=';
            let hasFirstFilterBeenApplied = false;

            if (selectedFilters?.sourceSystem) {
                newFilterQuery += `hmis_x0020_SourceSystem/SourceSystemKey eq ${selectedFilters.sourceSystem.value.sourceSystemKey}`;
                hasFirstFilterBeenApplied = true;

                if (selectedFilters?.organization) {
                    newFilterQuery += ` and hmis_x0020_Organization/OrganizationKey eq ${selectedFilters.organization.value.organizationKey}`;
                }

                if (selectedFilters?.coc) {
                    newFilterQuery += ` and hmis_x0020_CoC/CoCCode eq '${selectedFilters.coc.value.coCCode}'`;
                }
            }

            if (selectedFilters?.hmisProjectType) {
                newFilterQuery += `${hasFirstFilterBeenApplied ? ' and ' : ''}hmis_x0020_Project/ProjectTypeKey eq ${
                    selectedFilters.hmisProjectType.value.value
                }`;

                if (selectedFilters?.hmisProject) {
                    newFilterQuery += ` and hmis_x0020_Project/ProjectKey eq ${selectedFilters.hmisProject.value.projectKey}`;
                }
            }
            setFilterQuery(newFilterQuery);
        } else {
            setFilterQuery('');
        }
    }, [
        saveDashboardPreferences,
        selectedFilters?.coc,
        selectedFilters?.organization,
        selectedFilters?.hmisProject,
        selectedFilters?.hmisProjectType,
        selectedFilters?.sourceSystem,
        setFilterQuery,
        setSelectedFilters,
    ]);

    return (
        <div className="flex-row flex-wrap dashboard-filters">
            <Form.Group className="data-table-filter mr-3">
                <Form.Label>Source System</Form.Label>
                <Select
                    isClearable
                    className="filterSelect"
                    placeholder="Select Source System..."
                    value={selectedFilters?.sourceSystem ?? null}
                    options={sourceSystemOptions}
                    onChange={(selectedOption) => {
                        const sourceSystem = selectedOption as SelectOption<SourceSystem>;
                        setOrganizationOptions([]);
                        setCoCOptions([]);
                        setProjectOptions([]);
                        saveFilterPreferences({ sourceSystem }, ['sourceSystem', 'coc', 'organization']);

                        if (selectedOption?.value) {
                            populateOrganizations(selectedOption.value.sourceSystemKey);
                            populateCocs(selectedOption.value.sourceSystemKey);
                        }
                    }}
                />
            </Form.Group>

            {coCOptions.length > 0 && (
                <Form.Group className="data-table-filter mr-3 mb-2">
                    <Form.Label>CoC</Form.Label>
                    <Select
                        isClearable
                        className="filterSelect"
                        placeholder="Select CoC..."
                        value={selectedFilters?.coc ?? null}
                        options={coCOptions}
                        onChange={(selectedOption) => {
                            const coc = selectedOption as SelectOption<CoC>;
                            saveFilterPreferences({ coc }, ['coc']);
                        }}
                    />
                </Form.Group>
            )}

            <Form.Group className="data-table-filter mr-3 mb-2">
                <Form.Label>Project Type</Form.Label>
                <Select
                    isClearable
                    isDisabled={projectTypeOptions.length <= 0}
                    isLoading={areProjectTypesLoading}
                    className="filterSelect"
                    placeholder="Select Project Type..."
                    value={selectedFilters?.hmisProjectType ?? null}
                    options={projectTypeOptions}
                    onChange={(selectedOption) => {
                        const hmisProjectType = selectedOption as SelectOption<HmisProjectType>;
                        saveFilterPreferences({ hmisProjectType }, ['hmisProjectType', 'hmisProject']);
                        setProjectOptions([]);

                        if (selectedOption && selectedFilters?.organization) {
                            populateProjects(
                                selectedFilters?.organization.value.organizationKey,
                                selectedOption.value.value
                            );
                        }
                    }}
                />
            </Form.Group>

            {organizationOptions.length > 0 && (
                <Form.Group className="data-table-filter mr-3 mb-2">
                    <Form.Label>Organization</Form.Label>
                    <Select
                        isClearable
                        className="filterSelect"
                        placeholder="Select Organization..."
                        value={selectedFilters?.organization ?? null}
                        options={organizationOptions}
                        onChange={(selectedOption) => {
                            const organization = selectedOption as SelectOption<Organization>;
                            saveFilterPreferences({ organization }, ['organization', 'hmisProject']);
                            setProjectOptions([]);

                            if (selectedOption && selectedFilters?.hmisProjectType) {
                                populateProjects(
                                    selectedOption.value.organizationKey,
                                    selectedFilters.hmisProjectType.value.value
                                );
                            }
                        }}
                    />
                </Form.Group>
            )}

            {projectOptions.length > 0 && (
                <Form.Group className="data-table-filter mr-3 mb-2">
                    <Form.Label>Project</Form.Label>
                    <Select
                        isClearable
                        className="filterSelect"
                        placeholder="Select Project..."
                        value={selectedFilters?.hmisProject ?? null}
                        options={projectOptions}
                        onChange={(selectedOption) => {
                            const hmisProject = selectedOption as SelectOption<HmisProject>;
                            saveFilterPreferences({ hmisProject }, ['hmisProject']);
                        }}
                    />
                </Form.Group>
            )}
        </div>
    );
};

export default DashboardFilters;
