import { COLUMNS, DashboardVisual, LAYOUT_SHOWCASE, SPAN_TYPE } from './DashboardModels';
import { Page, Report, VisualDescriptor, factories, models, service } from 'powerbi-client';
import React, { FunctionComponent, RefObject, createRef, useCallback, useEffect, useState } from 'react';

import sortBy from 'lodash/sortBy';
import useWindowDimensions from '../Shared/WindowDimensions';

export interface EmbeddedReportProps {
    accessToken?: string;
    embedUrl?: string;
    datasetId?: string;
    columns: COLUMNS;
    span: SPAN_TYPE;
    layoutVisuals: DashboardVisual[];
    setLayoutVisuals: React.Dispatch<React.SetStateAction<DashboardVisual[]>>;
}

const EmbeddedReport: FunctionComponent<EmbeddedReportProps> = (props: EmbeddedReportProps) => {
    const { accessToken, embedUrl, columns, span, layoutVisuals, setLayoutVisuals, datasetId } = props;

    const windowDimensions = useWindowDimensions();

    const [reportContainerReference, setReportContainerReference] = useState<HTMLElement>();
    const [reportRef] = useState<React.Ref<HTMLDivElement>>(createRef());
    const [reportContainerDiv] = useState<JSX.Element>(
        <div id="report-container" className="dashboard-container" ref={reportRef}>
            Loading...
        </div>
    );
    const [powerbi] = useState(
        new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory)
    );

    const [layoutPageName, setLayoutPageName] = useState<string>();
    const [report, setReport] = useState<Report>();

    useEffect(() => {
        if (reportRef !== null && (reportRef as RefObject<HTMLDivElement>).current) {
            setReportContainerReference((reportRef as RefObject<HTMLDivElement>).current as HTMLDivElement);
        }

        return () => {
            if (reportContainerReference) {
                powerbi.reset(reportContainerReference);
            }
        };
    }, [powerbi, reportContainerReference, reportRef]);

    useEffect(() => {
        if (reportContainerReference) {
            powerbi.reset(reportContainerReference);
        }
    }, [powerbi, reportContainerReference]);

    useEffect(() => {
        if (accessToken && embedUrl && reportContainerReference) {
            const embedConfiguration: models.IReportEmbedConfiguration = {
                type: 'report',
                accessToken: accessToken,
                tokenType: models.TokenType.Embed,
                embedUrl: embedUrl,
                permissions: models.Permissions.All,
                ...(datasetId && { datasetBinding: { datasetId: datasetId } }),
                viewMode: models.ViewMode.View,
                id: process.env.REACT_APP_DASHBOARD_ID,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    layoutType: models.LayoutType.Custom,
                    background: models.BackgroundType.Transparent,
                    customLayout: {
                        displayOption: models.DisplayOption.FitToWidth,
                    },
                    panes: {
                        pageNavigation: {
                            visible: false,
                        },
                    },
                    useCustomSaveAsDialog: true,
                },
            };

            setReport(powerbi.load(reportContainerReference, embedConfiguration) as Report);
        }
    }, [accessToken, embedUrl, datasetId, powerbi, reportContainerReference, setReport]);

    useEffect(() => {
        // render only if report and visuals initialized
        if (!report || !layoutVisuals || !windowDimensions.width) {
            return;
        }

        // Get report-container width and height
        const reportContainer = reportRef as RefObject<HTMLDivElement>;

        const reportWidth = (reportContainer.current?.offsetWidth ?? 0) - 12;
        let reportHeight = 0;

        // Filter the visuals list to display only the checked visuals
        const checkedVisuals = layoutVisuals.filter((visual) => visual.checked);

        // Calculating the combined width of all visuals in a row
        let visualsTotalWidth = reportWidth - LAYOUT_SHOWCASE.MARGIN * (columns + 1);

        // Get all the available width for visuals total width, get the space from right margin of the report
        visualsTotalWidth += LAYOUT_SHOWCASE.MARGIN / 2;

        // Calculate the width of a single visual, according to the number of columns
        // For one and three columns visuals width will be a third of visuals total width
        const visualWidth = visualsTotalWidth / columns;

        // Building visualsLayout object
        // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Custom-Layout
        const visualsLayout: models.VisualsLayout = {};

        // Visuals starting point
        let x = LAYOUT_SHOWCASE.MARGIN;
        let y = LAYOUT_SHOWCASE.MARGIN;

        // Calculate visualHeight with margins
        let visualHeight = visualWidth * LAYOUT_SHOWCASE.VISUAL_ASPECT_RATIO;

        // Section means a single unit that will be repeating as a pattern to form the layout
        // These 2 variables are used for the 2 custom layouts with spanning
        const rowsPerSection = 2;
        const visualsPerSection = 3;

        // Calculate the number of rows
        let rows = 0;

        if (span === SPAN_TYPE.COLSPAN) {
            rows = rowsPerSection * Math.floor(checkedVisuals.length / visualsPerSection);
            if (checkedVisuals.length % visualsPerSection) {
                rows += 1;
            }
            reportHeight = Math.max(reportHeight, rows * visualHeight + (rows + 1) * LAYOUT_SHOWCASE.MARGIN);

            checkedVisuals.forEach((element, idx) => {
                visualsLayout[element.name] = {
                    x: x,
                    y: y,
                    width:
                        idx % visualsPerSection === visualsPerSection - 1
                            ? visualWidth * 2 + LAYOUT_SHOWCASE.MARGIN
                            : visualWidth,
                    height: visualHeight,
                    displayState: {
                        // Change the selected visuals display mode to visible
                        mode: models.VisualContainerDisplayMode.Visible,
                    },
                };

                // Calculating (x,y) position for the next visual
                x +=
                    LAYOUT_SHOWCASE.MARGIN +
                    (idx % visualsPerSection === visualsPerSection - 1 ? visualWidth * 2 : visualWidth);

                // Reset x if exceeding report width
                if (x + visualWidth > reportWidth) {
                    x = LAYOUT_SHOWCASE.MARGIN;
                    y += visualHeight + LAYOUT_SHOWCASE.MARGIN;
                }
            });
        } else if (span === SPAN_TYPE.ROWSPAN) {
            rows = rowsPerSection * Math.floor(checkedVisuals.length / visualsPerSection);
            if (checkedVisuals.length % visualsPerSection) {
                rows += 2;
            }
            reportHeight = Math.max(reportHeight, rows * visualHeight + (rows + 1) * LAYOUT_SHOWCASE.MARGIN);

            checkedVisuals.forEach((element, idx) => {
                visualsLayout[element.name] = {
                    x: x,
                    y: y,
                    width: visualWidth,
                    height: !(idx % visualsPerSection) ? visualHeight * 2 + LAYOUT_SHOWCASE.MARGIN : visualHeight,
                    displayState: {
                        // Change the selected visuals display mode to visible
                        mode: models.VisualContainerDisplayMode.Visible,
                    },
                };

                // Calculating (x,y) position for the next visual
                x += visualWidth + LAYOUT_SHOWCASE.MARGIN;

                // Reset x if exceeding report width
                if (x + visualWidth > reportWidth) {
                    x =
                        (idx + 1) % visualsPerSection === 0
                            ? LAYOUT_SHOWCASE.MARGIN
                            : 2 * LAYOUT_SHOWCASE.MARGIN + visualWidth;
                    y += idx % visualsPerSection === 0 ? visualHeight * 2 : visualHeight + LAYOUT_SHOWCASE.MARGIN;
                }
            });
        } else if (span === SPAN_TYPE.NONE) {
            if (columns === COLUMNS.ONE) {
                visualHeight /= 2;
            }

            rows = Math.ceil(checkedVisuals.length / columns);
            reportHeight = Math.max(reportHeight, rows * visualHeight + (rows + 1) * LAYOUT_SHOWCASE.MARGIN);

            checkedVisuals.forEach((element) => {
                visualsLayout[element.name] = {
                    x: x,
                    y: y,
                    width: visualWidth,
                    height: visualHeight,
                    displayState: {
                        // Change the selected visuals display mode to visible
                        mode: models.VisualContainerDisplayMode.Visible,
                    },
                };

                // Calculating (x,y) position for the next visual
                x += visualWidth + LAYOUT_SHOWCASE.MARGIN;

                // Reset x if exceeding report width
                if (x + visualWidth > reportWidth) {
                    x = LAYOUT_SHOWCASE.MARGIN;
                    y += visualHeight + LAYOUT_SHOWCASE.MARGIN;
                }
            });
        }

        // Building visualsLayout object
        // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Custom-Layout
        // Building pagesLayout object
        if (layoutPageName) {
            const pagesLayout: models.PagesLayout = {};
            pagesLayout[layoutPageName] = {
                defaultLayout: {
                    displayState: {
                        // Default display mode for visuals is hidden
                        mode: models.VisualContainerDisplayMode.Hidden,
                    },
                },
                visualsLayout: visualsLayout,
            };

            // Building settings object
            const settings: models.ISettings = {
                // Change page background to transparent
                background: models.BackgroundType.Transparent,
                layoutType: models.LayoutType.Custom,
                customLayout: {
                    pageSize: {
                        type: models.PageSizeType.Custom,
                        width: reportWidth,
                        height: reportHeight,
                    } as models.ICustomPageSize,
                    displayOption: models.DisplayOption.FitToPage,
                    pagesLayout: pagesLayout,
                },
            };

            // If reportWidth or reportHeight has changed, adjust the display option to actual size to add scroll bar
            if (
                (reportWidth !== reportContainer.current?.offsetWidth ||
                    reportHeight !== reportContainer.current?.offsetHeight) &&
                settings.customLayout
            ) {
                // Reset the height of the report-container to avoid the scroll-bar
                //resetContainerHeight(reportHeight);

                settings.customLayout.displayOption = models.DisplayOption.ActualSize;
            }

            // Call updateSettings with the new settings object
            report.updateSettings(settings);
        }
    }, [columns, layoutPageName, layoutVisuals, report, reportRef, span, windowDimensions.width]);

    const createVisualsArray = useCallback(
        async (reportVisuals: DashboardVisual[]) => {
            // Remove all visuals without titles (i.e., cards)
            setLayoutVisuals(
                reportVisuals.filter((visual) => visual.title !== undefined)
            );
        },
        [setLayoutVisuals]
    );

    useEffect(() => {
        if (report) {
            // Clear any other loaded handler events
            report.off('loaded');

            // Triggers when a report schema is successfully loaded
            report.on('loaded', async function () {
                const pages: Page[] = await report.getPages();

                // Retrieve first page.
                const activePage: Page = pages.filter((page: Page) => {
                    return page.isActive;
                })[0];

                // Set layoutPageName to active page name
                setLayoutPageName(activePage.name);

                // Get the visuals of the active page
                const visuals: VisualDescriptor[] = await activePage.getVisuals();

                const reportVisuals: DashboardVisual[] = visuals.map((newVisual: VisualDescriptor) => {
                    // Check the user's preference for displaying the visual, and use that checked status if found, otherwise default to checked
                    const visualPreference = layoutVisuals.find((existingVisual) => {
                        return existingVisual.name === newVisual.name;
                    });
                    return {
                        name: newVisual.name,
                        title: newVisual.title,
                        checked: visualPreference ? visualPreference.checked : true,
                    };
                });

                const sortedVisuals = sortBy(reportVisuals, (visual) =>
                    layoutVisuals.findIndex((existingVisual) => visual.name === existingVisual.name)
                );

                await createVisualsArray(sortedVisuals);

                // Implement phase embedding to first load the report, arrange the visuals and then render
                report.render();
            });
        }
    }, [createVisualsArray, layoutVisuals, report]);

    return <>{reportContainerDiv}</>;
};

export default EmbeddedReport;
