import { Alert, Button, Form, Modal } from 'react-bootstrap';
import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../../Shared/ApiHelper';
import { HmisProject } from '../../../Shared/SharedModels';
import { InformationFilled } from '@carbon/icons-react';
import { useMsal } from '@azure/msal-react';
import { useUserProfile } from '../../../Shared/contexts/UserProfile';

interface ProjectDeleteModalProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    activeProject: HmisProject | undefined;
    setActiveProject: Dispatch<SetStateAction<HmisProject | undefined>>;
    setProjects: Dispatch<SetStateAction<HmisProject[]>>;
}

const ProjectDeleteModal: FunctionComponent<ProjectDeleteModalProps> = (props: ProjectDeleteModalProps) => {
    const { showModal, setShowModal, activeProject, setActiveProject, setProjects } = props;

    const userProfile = useUserProfile();
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    return (
        <Modal
            scrollable
            show={showModal}
            onHide={() => setShowModal(false)}
            onExited={() => setActiveProject(undefined)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delete this Project?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="p-3">
                    <Alert variant="info">
                        <InformationFilled size={20} className="mr-2" />
                        This delete may take up to a minute to process within ClientInsight, you will be notified once
                        the deletion has been finished. If the project syncs to ClientTrack, the sync may not occur
                        until end of the business day.
                    </Alert>
                    <p>Project ID: {activeProject?.projectId}</p>
                    <p>Project Name: {activeProject?.projectName}</p>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outline-danger"
                    onClick={() => {
                        if (activeProject) {
                            setProjects((prev) =>
                                prev.filter((project) => project.projectKey != activeProject?.projectKey)
                            );

                            const payload = {
                                projectKey: activeProject?.projectKey,
                                sourceSystemKey: activeProject?.sourceSystem?.sourceSystemKey,
                                userKey: userProfile.user?.userKey,
                            };

                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_NET_API_URL + '/User/projects',
                                'DELETE',
                                JSON.stringify(payload)
                            ).then(async (_result: Response) => {
                                toast.success('Project Deleted Successfully', {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    transition: Slide,
                                    draggable: false,
                                    closeOnClick: false,
                                    theme: 'colored',
                                });
                            });

                            setShowModal(false);
                        }
                    }}
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProjectDeleteModal;
