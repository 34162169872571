import { HmisProject, SelectOption, SourceSystem } from '../../Shared/SharedModels';
import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import FilterResetButton from '../../Shared/components/FilterResetButton';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';

interface ProjectListFiltersProps {
    masterProjectsList: HmisProject[];
    setFilteredProjectsList: Dispatch<SetStateAction<HmisProject[]>>;
}

const ProjectListFilters: FunctionComponent<ProjectListFiltersProps> = (props: ProjectListFiltersProps) => {
    const { masterProjectsList, setFilteredProjectsList } = props;
    const userProfile = useContext(UserProfileContext);

    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState('');

    const [selectedSourceSystems, setSelectedSourceSystems] = useState<SelectOption<SourceSystem>[]>([]);
    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<SourceSystem>[]>([]);

    const [areFiltersApplied, setAreFiltersApplied] = useState(false);

    const clearFilters = useCallback(() => {
        setProjectName('');
        setProjectId('');
        setSelectedSourceSystems([]);
    }, []);

    useEffect(() => {
        setAreFiltersApplied(!!projectName || !!projectId || !!selectedSourceSystems.length);
    }, [projectId, projectName, selectedSourceSystems.length]);

    useEffect(() => {
        setSourceSystemOptions(
            userProfile.sourceSystems
                .filter((sourceSystem) => sourceSystem.sourceSystemKey !== 0)
                .map((sourceSystem) => {
                    return {
                        label: sourceSystem.sourceSystemName,
                        value: sourceSystem,
                    };
                })
                .sort((a, b) => (a.label > b.label ? 1 : -1))
        );
    }, [userProfile.sourceSystems]);

    useEffect(() => {
        let filteredProjects = masterProjectsList;

        if (projectName) {
            filteredProjects = filteredProjects.filter((project) => 
                project.projectName?.toLowerCase().includes(projectName.toLowerCase())
            );
        }

        if (projectId) {
            filteredProjects = filteredProjects.filter((project) => 
                project.projectId?.toLowerCase().includes(projectId.toLowerCase())
            );
        }

        if (selectedSourceSystems.length) {
            filteredProjects = filteredProjects.filter((project) =>
                selectedSourceSystems.find((system) =>
                    system.value.sourceSystemKey === project.sourceSystem?.sourceSystemKey
                )
            );
        }

        setFilteredProjectsList(filteredProjects);
    }, [masterProjectsList, projectName, projectId, selectedSourceSystems, setFilteredProjectsList]);

    return (
        <>
            <FilterResetButton clickCallback={clearFilters} filtersApplied={areFiltersApplied}/>

            <Form className="filterFormContainer">
                <Form.Group className="data-table-filter">
                    <Form.Label>Project ID</Form.Label>
                    <Form.Control
                        data-testid="projectIDFilter"
                        id="projectIDFilter"
                        type="text"
                        placeholder="Project ID..."
                        value={projectId}
                        onChange={(event) => {
                            setProjectId(event.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control
                        data-testid="projectNameFilter"
                        id="projectNameFilter"
                        type="text"
                        placeholder="Project Name..."
                        value={projectName}
                        onChange={(event) => {
                            setProjectName(event.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Source System</Form.Label>
                    <Select
                        data-testid="sourceSystemFilter"
                        id="sourceSystemFilter"                        
                        isMulti
                        isClearable
                        className="filterSelect"
                        placeholder="Select Source Systems..."
                        value={selectedSourceSystems}
                        options={sourceSystemOptions}
                        onChange={(selectedOptions) => {
                            setSelectedSourceSystems(selectedOptions as SelectOption<SourceSystem>[]);
                        }}
                    />
                </Form.Group>
            </Form>
        </>
    );
};

export default ProjectListFilters;
