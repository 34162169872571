import React, { useEffect, useRef, useState } from 'react';
import { factories, models, service } from 'powerbi-client';

import ApiHelper from '../../Shared/ApiHelper';
import { EmbeddedReportResponse } from '../../Reports/ReportModels';
import PrintReport from '../../Reports/PrintReport';
import SendReport from '../../Reports/SendReport';
import { Spinner } from 'react-bootstrap';
import styles from './ViewUsageReport.module.css';
import { useMsal } from '@azure/msal-react';

const ViewUsageReport: React.FC = () => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const reportRef = useRef<HTMLDivElement>(null);

    const [isLoading, setIsLoading] = useState(false);

    const [powerbi] = useState(
        new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory)
    );

    useEffect(() => {
        if (!reportRef.current) {
            return;
        }
        setIsLoading(true);
        powerbi.reset(reportRef.current as HTMLElement);

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_NET_API_URL}/pbiembed/view-usagereport`,
            'GET'
        ).then(async (result) => {
            const {
                embedToken,
                embedReport: [embedReport],
                datasetId,
            }: EmbeddedReportResponse = await result.json();

            const embedConfiguration: models.IReportEmbedConfiguration = {
                type: 'report',
                accessToken: embedToken.token,
                tokenType: models.TokenType.Embed,
                embedUrl: embedReport.embedUrl,
                datasetBinding: { datasetId: datasetId },
                permissions: models.Permissions.Read,
                viewMode: models.ViewMode.View,
                id: embedReport.reportId,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    layoutType: models.LayoutType.Custom,
                    background: models.BackgroundType.Transparent,
                    customLayout: {
                        displayOption: models.DisplayOption.FitToWidth,
                    },
                    panes: {
                        pageNavigation: {
                            visible: false,
                        },
                    },
                },
            };

            powerbi.embed(reportRef.current as HTMLElement, embedConfiguration);
            setIsLoading(false);
        });
    }, [api, instance, powerbi]);

    return (
        <div className={styles.container}>
            <div className={`${styles.actions}`}>
                <h1 className="content-title mt-0">Report Usage Metrics</h1>

                <div className={`ml-auto ${styles.actions}`}>
                    <PrintReport type="usage-report" isPublic={false} />
                    <SendReport type="usage-report" isPublic={false} />
                </div>
            </div>

            {isLoading && (
                <div className={styles.spinnerContainer}>
                    <Spinner className={styles.spinner} variant="primary" animation="border" />
                </div>
            )}

            <div ref={reportRef} className="mt-4" style={{ flex: !isLoading ? 1 : 0 }}></div>
        </div>
    );
};

export default ViewUsageReport;
