import { Container, Row } from 'react-bootstrap';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../Shared/contexts/UserProfile';

import ProjectCard from '../Shared/components/ProjectCard';
import { Settings } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';

const Admin: FunctionComponent = () => {
    const navigate = useNavigate();
    const userProfile = useContext(UserProfileContext);

    const projectCards = useMemo(() => [
        {
            title: "Report Categories",
            body: "Add, edit, or delete custom categories that can be applied to your reports for easier sorting and organization",
            projectType: "Category" as const,
            onClick: () => { navigate('reportcategories'); }
        },
        {
            title: "Data Quality Alerts",
            body: "Configure data quality thresholds for sending email alerts to desired recipients when your data does not meet your standards",
            projectType: "Alert" as const,
            onClick: () => { navigate('dataqualityalerts'); }
        },
        {
            title: "Source Systems",
            body: "Add, activate, or inactivate data sources to manage which providers are able to upload to the system",
            projectType: "SourceSystems" as const,
            onClick: () => { navigate('sourcesystems'); }
        },
        ...(isGlobalAdmin(userProfile) ? [
            {
                title: "Projects",
                body: "View project data, global admins may also delete projects in the case that invalid data has been uploaded",
                projectType: "Project" as const,
                onClick: () => { navigate('projects'); }
            },
            {
                title: "Public Report Reviewers",
                body: "Configure users that will receive notifications to review reports authored for public use before publishing",
                projectType: "Link" as const,
                onClick: () => { navigate('publicreportreviews'); }
            }
        ] : [])
    ], [navigate, userProfile]);

    return (
        <div className="content-column-container">
            <div className="content-header">
                <h1>Configuration</h1>
            </div>

            <p className="data-tools-description">Configure ClientInsight to meet the needs of your organization</p>
            <Container fluid className="mt-4">
                <Row>
                    {projectCards.map((card, index) => (
                        <ProjectCard
                            key={index}
                            title={card.title}
                            body={card.body}
                            projectType={card.projectType}
                            onClick={card.onClick}
                        >
                            <Settings size={24} className="button-icon-padding" data-testid="configure"/> Configure
                        </ProjectCard>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default Admin;