import './DatePicker.css';

import BaseDatePicker from 'react-datepicker';
import { Close } from '@carbon/icons-react';
import InputMask from 'react-input-mask';
import React from 'react';
import { isValidDate } from '../SharedHelpers';

export type DateValue = Date | null;
type DatePickerValue<WithRange> = WithRange extends true | undefined ? DateValue : [DateValue, DateValue];

interface DatePickerProps<WithRange extends true | undefined = undefined> {
    className?: string;
    isClearVisible?: boolean;
    selectsRange?: true;
    value: DatePickerValue<WithRange>;
    onChange(date: DatePickerValue<WithRange>): void;
}

const DatePicker = <WithRange extends true | undefined = undefined>({
    className,
    isClearVisible = true,
    selectsRange,
    value,
    onChange,
}: DatePickerProps<WithRange>): React.ReactElement => {
    const [startDate, endDate] = Array.isArray(value) ? value : [value];

    const baseMask = '99/99/9999';
    const inputMask = selectsRange ? `${baseMask} - ${baseMask}` : baseMask;

    const dateFormat = 'MM/dd/yyyy';

    const pickerProps = selectsRange ? { startDate, endDate } : { selected: startDate };

    const validateDate = (date?: DateValue): DateValue => (date && isValidDate(date) ? date : null);

    const checkDates = (firstDate: DateValue, secondDate?: DateValue): void => {
        const newValue = selectsRange ? [validateDate(firstDate), validateDate(secondDate)] : validateDate(firstDate);

        onChange(newValue as DatePickerValue<WithRange>);
    };

    const handleChange = (date: Date | Date[] | null): void => {
        const [firstDate, secondDate] = Array.isArray(date) ? date : [date];

        checkDates(firstDate, secondDate);
    };

    const handleClearClick = (): void => {
        const newValue = selectsRange ? [null, null] : null;
        onChange(newValue as DatePickerValue<WithRange>);
    };

    return (
        <div className={`date-picker-container ${className}`}>
            {selectsRange ? (
                <BaseDatePicker
                    {...pickerProps}
                    className="date-picker form-control"
                    dateFormat={dateFormat}
                    selectsMultiple
                    onChange={handleChange}
                    customInput={<InputMask mask={inputMask} />}
                    placeholderText="Enter Date..."
                />
            ) : (
                <BaseDatePicker
                    {...pickerProps}
                    className="date-picker form-control"
                    dateFormat={dateFormat}
                    onChange={handleChange}
                    customInput={<InputMask mask={inputMask} />}
                    placeholderText="Enter Date..."
                />
            )}

            {isClearVisible && !!value && (
                <div className="clear">
                    <button type="button" onClick={handleClearClick}>
                        <Close size={24} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default DatePicker;
