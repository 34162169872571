import { Button, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { Apps } from '@carbon/icons-react';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';

const AppsButton: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        if (userProfile.environmentConfig.clientTrackUrl) {
            setIsloading(false);
        }
    }, [userProfile.environmentConfig.clientTrackUrl]);

    const logSsoActivity = useCallback((): void => {
        const logEntry = {
            upn: userProfile.user?.upn,
            userRole: userProfile.user?.role?.name,
            ssoUrl: userProfile.environmentConfig.clientTrackUrl,
            defaultCustomerId: userProfile.user?.defaultCustomerId,
            customerId: userProfile.user?.customerId,
            isEccoviaUser: userProfile.isEccoviaUser,
        };

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/UsageLogging/report',
            'POST',
            JSON.stringify(logEntry)
        ).catch((e) => console.log(e));
    }, [
        api,
        instance,
        userProfile.environmentConfig.clientTrackUrl,
        userProfile.isEccoviaUser,
        userProfile.user?.customerId,
        userProfile.user?.defaultCustomerId,
        userProfile.user?.role?.name,
        userProfile.user?.upn,
    ]);

    return (
        <OverlayTrigger
            placement="bottom"
            rootClose
            trigger="click"
            overlay={
                <Popover className="apps-popover">
                    <Popover.Header className="apps-popover-header">
                        <h4 className="apps-popover-switch-text">Switch To</h4>
                    </Popover.Header>
                    <Popover.Body>
                        {isLoading ? (
                            <div className="flex-column">
                                <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                            </div>
                        ) : (
                            <>
                                <h4>
                                    <a href="/">
                                        <img src="logo-tm.svg" alt="ClientInsight" width="200" />
                                    </a>
                                </h4>
                                <h4>
                                    <a href={userProfile.environmentConfig.clientTrackUrl} onClick={logSsoActivity}>
                                        <img src="clienttrack-logo.svg" alt="ClientTrack" width="200" />
                                    </a>
                                </h4>
                            </>
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <Button variant="link" className="sidebar-brand-icon apps-button" title="appSwitcherButton">
                <Apps size={24} className="apps-icon" />
            </Button>
        </OverlayTrigger>
    );
};

export default AppsButton;
