import { Button, Spinner } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';

import { TrashCan } from '@carbon/icons-react';

interface SaveButtonProps {
    onClick: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
}

const SaveButton: FunctionComponent<SaveButtonProps> = (props: SaveButtonProps) => {
    const { onClick, isLoading, isDisabled } = props;

    return (
        <Button disabled={isLoading || isDisabled} variant="danger" className="save-button" onClick={onClick}>
            {isLoading ? (
                <>
                    <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" /> Loading...
                </>
            ) : (
                <>
                    <TrashCan size={24} className="button-icon-padding" />
                    Delete
                </>
            )}
        </Button>
    );
};

export default SaveButton;
