import React, { RefObject, forwardRef } from 'react';

import { Nav } from 'react-bootstrap';

interface NavDropdownProps {
    children?: React.ReactNode;
    onClick: (e: React.MouseEvent) => void;
}

const DropdownNavItem = forwardRef(
    (
        { children, onClick }: NavDropdownProps,
        ref: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined
    ) => (
        <Nav.Item
            data-testid="NavDropdown-NavItem"
            ref={ref}
            onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </Nav.Item>
    )
);
DropdownNavItem.displayName = 'DropdownNavItem';

export default DropdownNavItem;
